@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

/* Add Animation */
@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}

	to {
		top: 0;
		opacity: 1
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}

	to {
		top: 0;
		opacity: 1
	}
}

body {
	background-color: #030f2e;
	scroll-behavior: smooth;
	transition: all 0.5s ease-in-out;
}

.App {
	text-align: center;
	overflow: hidden;
}

.text-left {
	text-align: left;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.bodyScroll {
	height: calc(100vh - 400px);
	overflow: auto;
}

.bodyScroll2 {
	height: calc(100vh - 350px);
	overflow-y: auto;
	overflow-x: hidden;

}


@media (min-width: 1024px) {
	.bodyScroll2 {
		width: 900px;
	}
}

@media (min-width: 992px) {
	.bodyScroll2 {
		width: 900px;
	}
}

@media (min-width: 768px) {
	.bodyScroll2 {
		width: 699px;
	}
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.MemberPopover .MuiPopover-paper{
	background: transparent;
	
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.main-nav {
	background-color: #030F2E;
	color: white !important;
	font-family: 'Poppins', sans-serif;
}

.main-bg {
	background: url("../src/images/sky BG_v2.png") no-repeat;
	background-size: 100%;
	/* background-position:center; */
}

.main-page {
	min-height: 100vh;
}

.second-bg {
	background: url("../src/images/sky-Team 1.png") no-repeat;
	background-size: 100%;
	background-position: 0 -50px;
}

.main-p {
	padding-left: 100px;
	padding-right: 100px;
}

.partners-block {
	background-color: #030f2e;
}

h1,
h2,
h4,
h3,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
}

body {
	font-family: 'Hind', serif;
}

.main-text-div {
	font-family: 'Mulish', sans-serif;
	text-align: left;
	font-size: 16px;
	max-width: 655px;
}

.nft-btn {
	background-color: #5761D7;
	border-radius: 67px;
	color: white;
	border: 1px solid #5761D7;
	/* margin-top: -50px !important; */
	font-family: 'Hind', sans-serif;
	font-size: 12px;
	display: inline-block;
	width: 140px;
}

.nft-btn-holder {
	margin-top: -8px;
}

.cat-feet {
	margin-left: -30px;
	margin-top: -2px;
}

.purp {
	color: #5761D7;

}

.purp-bg {
	background-color: #5761D7;
}

.pill-btn {
	background-color: #5761D7;
	border-radius: 67px;
	color: white;
	border: 1px solid #5761D7;
	font-family: 'Hind', sans-serif;
	font-size: 12px;
	width: max-content;
}

.outline-btn {
	background-color: transparent;
	border-radius: 67px;
	color: white;
	border: 1px solid #5761D7;
	font-size: 12px;
	font-family: 'Hind', sans-serif;
	/*width: 135px;*/
	width: max-content;
}

.intro-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.intro-column p {
	margin: 0 0 30px 0;
	max-width: 400px;
}

/* .block-btns {} */
.desc-text {
	max-width: 400px;
	font-size: 18px;
}

.multi-chain p {
	max-width: 500px;
}

.media-box {
	/* background-image: linear-gradient(147.34deg, rgba(255, 255, 255, 0.046) 2.83%, rgba(87, 97, 215, 0.108) 22.87%, rgba(255, 255, 255, 0.046) 100%); */
	/* background-image: linear-gradient(to right rgb(0, 0, 0), rgb(255, 255, 255), rgb(0, 0, 0)); */
	/* background-size: 100% 2px, 2px 100%, 100% 2px, 2px 100%; */
	/* background-origin: border-box; */
	position: relative;
	padding-top: 9px;
	padding-bottom: 2px;
	color: #fff;
	border-top: 2px solid transparent;
	border-image: linear-gradient(to left, rgba(255, 255, 255, 0.6), transparent) 1;

}

.media-box::after {
	position: absolute;
	width: 100%;
	left: 0;
	content: " ";
	border-bottom: 3px solid transparent;
	border-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.6)) 1;

}

.media-wrapper {
	background-color: #0c173c;
}

.bg-blue {
	background-color: #040F2E;
}

.overflow-hidden {
	overflow-y: hidden;

}

.multi-chain {
	max-width: 700px;
}

.footer {
	background-color: #2F2E41;
}

.footer-text {
	font-family: "Hind", sans-serif !important;
	text-align: left;
}

a:hover {
	cursor: pointer;
	color: #fff;
}

.popup-bg {
	background-color: #1E263D !important;
}

.font-m {
	font-size: 14px !important;
}

.pl-1 {
	padding-left: 1rem;
}

.mt-3rem {
	margin-top: 3rem;
}

.close-btn {
	margin-top: -12px;
}

.close-btn:hover {
	cursor: pointer;
}

.pl-2 {
	padding-left: 1.5rem !important;
}

.center-div {
	max-width: 600px;
}

.center-div p {
	margin: 0;
}

.member {
	position: relative;
	max-width: 400px;
	min-width: 200px;
}

.members-container {
	padding: 3em 3em 0 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
}

.purp-overlay {
	position: absolute;
	left: 0px;
	width: 100%;
	height: 100%;
}

.member-info {
	position: absolute;
	background: transparent;
	backdrop-filter: blur(8px);
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	cursor: pointer;
	padding: 20px;
	text-align: left;
}

.member-popup {
	max-width: 565px;
	min-height: 400px;
	padding: 30px;
}

.member-tags-container {
	display: flex;
	justify-content: space-between;
}

.member-tags {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.font-sm {
	font-size: 14px;
}

h6 {
	font-size: 18px;
}

.font-xs {
	font-size: 12px;
}

.font-nm {
	font-size: 16px;
}

.carousel-img {
	max-width: 180px;
}

.pr-0 {
	padding-right: 0px !important;
}

.pl-0 {
	padding-left: 0px !important;
}

.mr-3 {
	margin-right: 2rem !important;
}

.arrow:hover {
	cursor: pointer;
}

.font-md {
	font-size: 20px;
}

.popup-member {
	/* min-width: 62px; */
	width: 70px;
	height: 70px;
	/* min-height: 62px; */
	border-radius: 50%;
	object-fit: cover;
}

a {
	text-decoration: none;
	color: white;
}

.cat-button-div {
	margin-top: -150px;
}

.partners {
	max-height: 110px;
	max-width: 230px !important;
}

.chan-img {
	width: 55px;
	height: 55px;
}

.chan-img2 {
	width: 200px !important;
	height: 55px;
	object-fit: contain;
	object-position: center;
}

.video {
	position: relative;
	padding: 0 0 50% 0;
}

.video video {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.footer-down-text {
	font-size: 14px;
}

.rust {
	max-width: 163px !important;
}

.summary {
	background: #1E263D;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.summary-text {
	background: #FFFFFF;
	border-radius: 0 0 10px 10px;
	margin-top: 5px;
	color: #5761D7;
	font-size: 14px;
	padding: 15px;
}

details>summary::-webkit-details-marker {
	display: none;
}

.back-link {
	margin-bottom: 90px;
}

.back-link img {
	margin-right: 8px;
}

.browser-wrapper {
	background-color: #0c173c;
	box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(15px);
	border-radius: 0 0 10px 10px;
	width: 100%;
}

.browser-panel {
	background: #232526CC;
	border-radius: 10px 10px 0 0;
	padding: 12px 20px 12px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.browser-address {
	display: flex;
	align-items: center;
	flex: 0.8;
}

.browser-controls {
	display: flex;
	justify-content: space-between;
	width: 50px;
	margin-right: 27px;
}

.browser-arrow-down {
	margin-left: 11px;
	margin-right: 20px;
}

.browser-arrow-left {
	margin-right: 19px;
}

.browser-controls .red {
	border-radius: 50%;
	background-color: #ED6A5E;
	padding: 6px;
}

.browser-controls .yellow {
	border-radius: 50%;
	background-color: #F4BF4E;
	padding: 6px;
}

.browser-controls .green {
	border-radius: 50%;
	background-color: #62C554;
	padding: 6px;
}

.browser-domain {
	background-color: #3D4146;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	padding: 5px;
	flex: 0.7;
}

.browser-side {
	font-family: 'Poppins', serif;
	background-color: #181E30;
	min-width: 200px;
	min-height: 700px;
}

.browser-side-content {
	padding: 0 20px 0 20px;
}

.browser-side-content:last-child {
	margin-bottom: 70px;
}

.browser-side-title {
	text-align: left;
	color: #FFFFFF;
	padding: 10px;
}

.browser-side-title span {
	font-family: 'Poppins', serif;
	font-weight: 600;
	font-size: 14px;
}

.address {
	font-weight: 500;
	font-size: 13px;
}

.browser-line {
	border-top: 1px solid #5D606E;
	margin: 0 -20px;
}

.browser-side-summary {
	background-color: #5761D71A;
	padding: 5px 15px;
	margin-top: 20px;
	border-radius: 5px;
	color: #FFFFFF;
}

.browser-side-summary a {
	font-size: 10px;
	font-weight: 300;
	text-align: left;
	line-height: 15px;
}

details[open] .faq-arrow {
	transform: rotate(180deg);
}

details[open]>.side-summary {
	color: #5761D7;
	font-weight: 700;
	padding: 0 15px 7px 15px;
	border-bottom: 1px solid #E8E9ED;
	width: calc(100% + 18px);
	margin-left: -9px;
}

details[open] .side-arrow * {
	fill: #5761D7;
}

@keyframes fadeInDown {
	0% {
		height: 0;
		/*opacity: 0;*/
		/*transform: translateY(-2em);*/
	}

	25% {
		height: 20px;
	}

	50% {
		height: 50px;
	}

	100% {
		height: auto;
		/*opacity: 1;*/
		/*transform: translateY(0);*/
	}
}

details[open]>summary~* {
	animation-name: fadeInDown;
	animation-duration: 0.1s;
}

.side-summary {
	font-weight: 300;
	font-size: 12px;
}

.browser-content-wrapper {
	display: flex;
}

.browser-side-summary>summary {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.browser-side-summary-text {
	font-family: 'Hind', serif;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 13px;
	color: #FFFFFF;
	margin: 10px 0;
	max-width: 165px;
	text-align: left;
}

#nftTools {
	margin-top: 0;
}

.browser-side-summary-content {
	margin-top: 20px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	font-weight: 300;
	font-size: 10px;
}

.browser-content {
	background-color: #0D183B;
	padding: 0 30px 0 30px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.browser-content-img {
	width: 70%;
}

.browser-content-text {
	text-align: left;
	width: 50%;
}

.browser-content-text h5 {
	font-size: 18px;
}

.browser-content-text p {
	font-family: 'Hind', serif;
	text-align: left;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}

/*.browser-side-summary-content a:active,*/
/*.browser-side-summary-content a:focus*/
/*{*/
/*  font-weight: 800;*/
/*}*/

.browser-side-summary-content a:hover {
	color: #FFFFFF;
}

.coming-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Poppins', serif;
	color: #FFFFFF;
	width: 100%;
	padding: 180px 25px 180px 25px;
}

.coming-stay {
	display: flex;
	justify-content: center;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: 30px;
	margin-left: 20px;
}

.coming-soon {
	display: flex;
	justify-content: center;
	font-style: italic;
	font-weight: 300;
	font-size: 64px;
	line-height: 96px;
}

.coming-soon span {
	font-weight: 900;
	font-size: 64px;
	line-height: 96px;
	color: #5761D7;
}

.slide-wrapper {
	display: flex;
	background-color: #232756;
	justify-content: space-between;
	align-items: center;
	gap: 100px;
	color: #FFFFFF;
	padding: 40px 100px;
}

.slide-text {
	text-align: left;
}

.slide-text a {
	text-decoration: underline;
}

.slide-text p {
	font-family: 'Mulish', sans-serif;
	text-align: left;
	font-size: 14px;
}

.slide-text h5 {
	text-align: left;
	font-size: 18px;
	font-weight: bold;
}

.slider-arrow {
	cursor: pointer;
}

.jobs-fonts {
	font-family: 'Poppins', serif;
}

.jobs-fonts {
	font-family: 'Poppins', serif;
}

.responsive-img {
	height: 100%;
	box-sizing: border-box;
	/*max-width: 100%;*/
	/*max-height: 100%;*/
	width: 100%;
}
.responsive-video {
	height: 100%;
	box-sizing: border-box;
	/*max-width: 100%;*/
	/*max-height: 100%;*/
	width: 100%;
  background: Linear-Gradient(#fe18a6, #5761d7, #448bdd);
  padding: 1px;
  border-radius: 10px;
  overflow: hidden;
  
}

.responsive-video > video {
	border-radius: 10px;

}

.mission-btn {
	padding: 11px 34px;
	margin-top: 24px;
	filter: drop-shadow(0px 4px 16px rgba(255, 255, 255, 0.2));
	text-align: center;
}

.privacy-policy h4 {
	font-size: 16px;
	font-weight: 600;
}

.terms-conditions h5 {
	font-size: 16px;
	font-weight: 600;
}


.mission-container {
	padding: 52px 100px 100px 100px;
}

.career-position-details {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 20px;
}

.career-position-details svg {
	margin: 0 10px;
}

.job-popup-container h5 {
	font-size: 16px;
	font-weight: 600;
}

.our-mission-container h5 {
	font-size: 24px;
	font-weight: 700;
	margin: 0;
}

.our-mission-text p {
	max-width: 600px;
	margin: 0;
}

.footer-icons {
	display: flex;
	gap: 13px
}

.footer-icons-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.job-position-desk {
	padding: 40px 50px;
}

.job-position-desk h2 {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 15px;
}

.job-position-description {
	margin: 0;
}

.footer-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer-links-container {
	display: flex;
	gap: 120px;
}

.team-title {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
}

.team-second-title {
	font-family: 'Poppins', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
}

.team-text {
	font-family: 'Hind', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	max-width: 550px;
}

a.tab-active {
	font-weight: 800;
}

.pill-btn,
.outline-btn {
	padding: 12px 35px;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	width: 180px;
}

.outline-btn {
	margin-left: 20px;
}

.pill-btn:hover,
.outline-btn:hover {
	box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}

.Backers {
	justify-content: center;
	align-items: center;
}

.desc-image {
	margin-right: 10%;
}

.PPolicy {
	display: flex;
}

.business {
	background-color: #1E263D;
}

.business .businessInn {
	display: flex;
	align-items: center;
	text-align: left;
}

.business .bText {
	padding-left: 20%;
	font-size: 24px;
}

@media screen and (max-width: 1920px) {
	.cat-button-div {
		margin-top: -80px;
	}

	.members-container {
		width: 1440px;
		margin: auto;
	}
}

@media screen and (max-width: 1680px) {
	.main-page {
		min-height: calc(100vh - 200px);
	}

	.business .bText {
		padding-left: 15%;
	}

	.members-container {
		width: auto;
		margin: auto;
	}
}

@media screen and (max-width: 1445px) {
	.cat-button-div {
		margin-top: -20px;
	}

	.business .bText {
		padding-left: 10%;
	}
}

@media screen and (max-width: 1386px) {
	.main-page {
		min-height: calc(100vh - 380px);
	}
}

@media screen and (max-width: 1280px) {
	.main-page {
		min-height: calc(100vh - 200px);
	}

	.slide-wrapper {
		padding: 0 50px;
	}
}

@media screen and (max-width: 1080px) {
	.slide-wrapper {
		padding: 0 30px;
	}
}

@media screen and (max-width: 1024px) {
	.cat-button-div {
		margin-top: -10px;
	}
}

@media screen and (max-width: 992px) {
	#root {
		overflow-x: hidden !important;
	}

	.main-bg {
		background-size: cover;
		background-position: center;
		overflow: hidden;
	}

	.second-bg {
		padding-top: 50px !important;
	}

	.partners {
		padding: 10px 0;
	}

	.block-btns {
		justify-content: center;
	}

	.footer-links {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 900px) {
	.cat-button-div {
		margin-top: 50px;
	}

	.cat-button-div .text-start {
		text-align: center !important;
	}

	.business {
		height: 500px !important;
	}

	.business .businessInn {
		text-align: center;
	}

	.business .bText {
		padding-left: 0;
	}
}

@media screen and (max-width: 768px) {

	.footer-icons {
		flex-direction: row;
		justify-content: center;
	}

	.footer-icons-container {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.footer-container {
		flex-direction: column;
		align-items: center;
	}

	.footer-links-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0;
	}

	.footer-text {
		text-align: center;
	}

}

@media screen and (max-width:746px) {
	.intro-column p {
		width: 270px;
		margin: 30px;
	}

	.second-bg {
		background: url("../src/images/sky-BG-mobile.png") no-repeat;

	}

	.desc-image {
		width: 100%;
		margin: auto;
	}

	.multi-chain p {
		max-width: 310px;
	}

	.members-container {
		padding: 0;
		flex-direction: column;
		flex-wrap: nowrap;
		gap: 0
	}

	.center-div {
		margin-bottom: 60px;
	}

	.member {
		position: relative;
		max-width: calc(100% + 100px);
		width: calc(100% + 100px);
	}

	.member-info {
		position: absolute;
		background: transparent;
		-webkit-backdrop-filter: blur(15px);
		backdrop-filter: blur(15px);
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		width: 85%;
		cursor: pointer;
		padding: 20px;
	}

	.MuiPaper-elevation8 {
		max-width: calc(100% + 32px) !important;
		margin-left: -20px;
		height: 530px;
	}

	.MuiPaper-elevation8 p {
		width: 360px;
	}

	.MuiPaper-elevation8 .font-sm {
		font-size: 16px;
	}

	.member-popup {
		position: relative;
		height: 530px;
	}

	.member-popup .linked {
		position: absolute;
		bottom: 20px;
	}

	.member-name {
		margin-left: 0;
	}
}

@media screen and (max-width: 600px) {
	.px-5 {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.main-p {
		padding-left: 30px;
		padding-right: 30px;

	}

	.text-start {
		text-align: center !important;
		
	}
}

.owl-line-item {
	display: flex;
	align-items: center;
	justify-content: center;
}

.business {
	height: auto !important;
}

/* 
@media screen and (max-height: 1024px) {
	.main-page {
		min-height: calc(100vh - 350px);
	}
} */