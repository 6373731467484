html,
body {
	overflow-x: hidden;
}

.navbar-burger {
	display: none;
}

.navbar {
	position: fixed;
	width: 100%;
	z-index: 9999;
}

.nav-item {
	display: flex;
	align-items: center;
}

/*Careers*/

.photo-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 75px;
	width: calc(100% + 220px);
	margin-left: -110px;
	gap: 55px;
}


.photo-wrapper>div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.photo-item img {
	max-width: 180px;
	max-height: 180px;
}

.photo-item-crop-right img {
	padding-right: 13px;
}

.photo-item-crop-left img {
	padding-left: 13px;
}

/*job position*/

.jobs-fonts .filter-btn {
	cursor: pointer;
}

.jobs-fonts .filter-btn.active {
	background: linear-gradient(to right, #448add, #fd18a6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
}

@media screen and (min-width: 1441px) {
	.photo-wrapper>div {
		justify-content: center;
		gap: 72px;
	}
}

@media screen and (max-width: 1439px) {
	.photo-wrapper .photo-item img {
		width: 100%;
	}
}

@media screen and (max-width: 992px) {

	/*main*/
	.main-page {
		padding-top: 92px !important;
	}

	.main-page h2 {
		font-size: 20px;
		font-weight: bold;
	}

	.mob-center-text {
		text-align: center !important;
	}

	.main-text-div {
		margin: 0 auto;
	}

	.block-btns {
		margin: 0 auto;
		display: flex;
		flex-direction: row;
	}

	/*parters*/

	.partners-block .first-block {
		margin-bottom: -20px !important;
	}

	/*backers*/

	.hgventures {
		margin: -30px !important;
	}

	/*main anmol tools*/

	.browser-panel {
		background: #232526CC;
		border-radius: 10px 10px 0 0;
		padding: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.browser-controls {
		margin-right: 0;
		width: 41px;
		height: 9px;
	}

	.browser-arrow-left {
		margin-right: 5px;
	}

	.browser-arrow-down {
		margin-left: 5px;
		margin-right: 5px;
	}

	.browser-controls .red,
	.browser-controls .yellow,
	.browser-controls .green {
		padding: 5px;
	}

	.browser-address {
		flex: auto;
		gap: 6px;
	}

	.address {
		font-size: 9px;
	}


	.address img {
		margin-right: 4px !important;
		margin-bottom: 6px !important;
		width: 15px;
		height: 15px;
	}

	.address .address-lock {
		width: 8px;
		height: 10px;
		margin-top: 4px;
	}

	.browser-domain {
		flex: auto;
	}

	.browser-panel-cross {
		display: none;
	}

	/*mobile browser*/

	.browser-mobile-wrapper {
		width: 100%;
		background: #181E30;
		border-radius: 0 0 10px 10px;
	}

	.browser-mobile-title {
		width: 100%;
		padding: 14px;
		font-family: 'Poppins', serif;
		color: #fff;
		font-size: 14px;
		text-align: left;
		border-bottom: 1px solid #fff;
	}

	.browser-mobile-nav {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		padding: 13px 0;
		color: #fff;
		font-size: 12px;
		border-bottom: 1px solid #fff;
		background: #1E2541;
		-webkit-overflow-scrolling: touch;
	}

	.browser-mobile-nav .item {
		padding: 0 15px;
		flex: 0 0 auto;
		cursor: pointer;
	}

	.browser-mobile-nav .item.active {
		color: #5761D7;
	}

	.browser-mobile-subs {
		padding: 27px 20px 0;
	}

	.browser-mobile-sub-item {
		position: relative;
		height: 45px;
		overflow: hidden;
		padding: 19px 20px 20px;
		margin-bottom: 20px;
		background: #1E2541;
		color: #fff;
		border-radius: 5px;
		cursor: pointer;
		text-align: left;
	}

	.browser-mobile-sub-item:after {
		content: "";
		display: block;
		position: absolute;
		top: 19px;
		right: 20px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 7px solid #fff;
		transform: rotate(180deg);
	}

	.browser-mobile-sub-item.active:after {
		transform: rotate(0);
	}

	.browser-mobile-sub-item.active {
		height: auto;
		overflow: inherit;
	}

	.browser-mobile-sub-item h4 {
		margin-bottom: 20px;
		font-size: 12px;
	}

	.browser-mobile-sub-item.active h4 {
		font-weight: bold;
	}

	.browser-mobile-sub-item h5 {
		margin-bottom: 12px;
		font-size: 13px;
	}

	.browser-mobile-sub-item p {
		margin-bottom: 18px;
		font-size: 12px;
		line-height: 19px;
	}

	.browser-mobile-sub-item img {
		width: 100%;
	}

	.coming-wrapper {
		padding: 0;
	}

	.coming-wrapper .coming-stay {
		font-size: 15px;
		line-height: 17px;
		letter-spacing: 6px;
	}

	.coming-soon {
		font-size: 20px;
		line-height: 60px;
	}

	.coming-soon span {
		font-size: 21px;
		line-height: 60px;
	}

	/*multi-chain*/

	.multi-chain-mob {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.multi-chain-mob>div {
		padding-top: 0 !important;
	}

	/*slider*/

	.slide-wrapper {
		padding: 0;
		gap: 20px;
		flex-direction: column;
	}

	.slide-wrapper img {
		width: 77%;
		margin-bottom: 70px;
	}

	.slide-text h5 {
		color: #5761D7;
		text-align: center;
		padding-top: 30px;
		max-width: 520px;
		margin: 0 auto 13px;
	}

	.slide-text p {
		text-align: center;
		padding: 0 15px;
		margin: 0;
		max-width: 500px;
	}

	.slider-arrow {
		display: none;
	}

	.swiper-pagination {
		position: absolute;
		display: flex;
		bottom: 30px;
		z-index: 10;
		gap: 16px;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.swiper-pagination-bullet {
		display: block;
		width: 7px;
		height: 7px;
		border-radius: 10px;
		background: #fff;
	}

	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: #5761D7;
		width: 9px;
		height: 9px;
	}

	/*header*/
	.navbar {
		position: fixed;
		width: 100%;
		padding-left: 30px;
		padding-right: 30px;
		z-index: 100;
	}

	.navbar-toggler {
		padding: 0;
	}

	.navbar-burger {
		display: flex;
		position: relative;
		align-items: center;
		width: 30px;
		height: 30px;
	}

	.navbar-burger-lines {
		display: block;
		position: relative;
		width: 100%;
		height: 3px;
		border-radius: 10px;
		background: #ffffff;
		transition: all .3s;
	}

	.navbar-burger-lines.active {
		transform: rotate(45deg);
	}

	.navbar-burger-lines:before {
		content: '';
		display: block;
		position: absolute;
		top: -9px;
		left: 0;
		width: 100%;
		height: 3px;
		border-radius: 10px;
		background: #ffffff;
	}

	.navbar-burger-lines.active:before {
		display: none;
	}

	.navbar-burger-lines:after {
		content: '';
		display: block;
		position: absolute;
		top: 9px;
		left: 0;
		width: 100%;
		height: 3px;
		border-radius: 10px;
		background: #ffffff;
	}

	.navbar-burger-lines.active:after {
		top: 1px;
		transform: rotate(90deg);
	}

	/*Careers*/

	.career-card {
		padding-top: 0 !important;
	}

	.career-card .pill-btn {
		width: fit-content;
	}

	.photo-wrapper {
		margin-bottom: 50px;
		gap: 3px;
		width: calc(100% + 210px);
		margin-left: -104px;
	}

	.our-mission-container>div {
		padding: 0 !important;
	}

	.our-mission-container h5 {
		margin-bottom: 10px;
		font-size: 20px;
	}

	.job-title-mob {
		width: 100% !important;
		padding: 0 45px !important;
	}

	.career-position {
		display: flex;
		flex-direction: column;
		text-align: left;
	}

	.career-position>div {
		width: 100%;
	}

	.jobs-fonts {
		font-size: 14px;
	}

	.jobs-fonts>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;
	}

	/* .career-position .career-position-block {

    } */

	.career-position .career-position-button {
		height: 50px;
	}

	.career-position .mode {
		padding-left: 12px !important
	}

	[cssClass='bootstrap-dialog'] div:nth-child(3)>div {
		margin: 0;
		max-height: 100%;
	}

	.job-position-mobile-wrap {
		text-align: center;
		padding: 30px 10px !important;
	}

	.job-position-mobile-wrap .close-btn {
		margin-top: 0;
		right: 8px;
		top: 8px;
		position: absolute;
	}

	.job-position-mobile-wrap h2 {
		font-size: 20px;
		font-weight: bold;
	}

	.job-position-mobile-p {
		padding: 20px 15px !important;
		margin-top: 0 !important;
	}

	.job-position-mobile-p h5 {
		font-size: 14px;
	}

	.job-position-mobile-p button {
		margin: 0 auto;
		display: block;
	}

	/*footer*/

	.footer .text-start {
		text-align: center !important;
	}

	.footer-icons {
		flex-direction: row;
		justify-content: center;
	}

	.footer-down-text {
		flex-direction: column;
		flex-flow: column-reverse;
	}

	.footer-down-text>div {
		width: 100%;
		margin: 0 !important;
	}

	.social-container {
		padding-left: 8px;
	}

	.social-container>div {
		margin-bottom: 17px !important;
	}

	.footer-mail {
		margin: 40px 0 !important;
	}

	.footer-links {
		margin-bottom: 40px;
	}

	.footer-links div {
		margin-bottom: 17px !important;
	}

	/*about us*/

	.about-us-mob {
		padding-top: 0 !important;
	}

	.about-us-mob>div h4 {
		font-size: 18px;
	}

	.about-us-mob>div h3 {
		font-size: 20px;
	}

	.about-us-mob>div p {
		font-size: 14px;
	}

	/* .member{
        margin: 0 auto;
    } */

	.member-tags-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: revert;
	}

	/* .member-popup .linked {
		position: absolute;
		display: flex;
		align-items: flex-end;
		height: 480px;
	} */

	.member-tags {
		display: flex;
		align-items: center;
		gap: 3px;
		flex-direction: row-reverse;
		justify-content: flex-end;
		flex-wrap: wrap;
		margin-bottom: 9px;
	}

	.mission-container {
		padding: 20px 20px 40px;
	}

	.mission-container p {
		font-size: 14px;
	}

	/*jobs*/

	/*jobs popup*/

	.job-popup .MuiDialog-container {
		background: red !important;
	}

	/*know-more-mob*/

	.know-more-mob {
		margin-bottom: 60px !important;
		padding: 80px 20px 0px !important;
	}

	.know-more-mob .back-link {
		margin-bottom: 25px;
	}

	.know-more-mob h4 {
		margin-bottom: 10px !important;
		font-size: 20px;
	}

	.know-more-mob button {
		margin: 10px 0 50px;
	}

	.know-more-mob-content {
		padding: 0 20px !important;
	}

	.know-more-mob-content h5 {
		margin-bottom: 15px !important;
	}

	.know-more-mob-content p {
		font-size: 14px;
	}

	.know-more-mob-content svg.faq-arrow {
		position: absolute;
		right: 32px;
	}

	.know-more-mob-content .summary {
		padding: 15px 31px 15px 15px;
	}

}

/* .navbar-nav {
	padding-top: 10px;
} */

@media screen and (max-width: 767px) {
	.navbar-light .navbar-nav .nav-link {
		padding: 12px 0;
	}

	.navbar-nav {
		padding: 20px 0;
	}

	/* *,::after, ::before {
		overflow: hidden;
	} */
	.main-p .first-block h4 {
		padding-top: 70px;
		padding-bottom: 30px;
	}

	.member-popup .linked {
		position: absolute;
		display: flex;
		align-items: flex-end;
		height: 480px;
	}

	.partners {
		max-width: 150px !important;
	}

	.member-tags {
		flex-wrap: nowrap;
	}

	.career_icon {
		width: 80%;
	}


	.linked {
		top: 20px;
		bottom: 40px;
	}

	.pill-btn,
	.outline-btn {
		padding: 12px 0;
		font-size: 14px;
		width: 130px;
		font-weight: normal;
	}

	.outline-btn {
		color: #5761D7;
	}

	.outline-btn:hover {
		color: #fff;
	}

	.Backers {
		justify-content: center;
		align-items: center;
	}

	/* .ourBackers
  {
      background-color: #5761D7;
  } */
	.ourBackers img {
		width: 70px;
	}

	.ourBackers .imgSmall {
		width: 50px;
	}

	.ourBackers .imgBig {
		width: 120px;
	}

	.ourBackers h4 {
		font-size: 14px;
	}

	/* 
	.slickSlider img {
		width: 50px !important;
	} */

	.chan-img2 {
		width: 250px !important;
	}

	.PPolicy {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: row-reverse;
	}

	.PPolicy div {
		margin: 5px !important;
	}
}

@media screen and (max-width: 600px) {
	.photo-wrapper {
		width: calc(100% + 76px);
		margin-left: -38px;
	}

	.slide-text p {
		padding: 0 65px;
		line-height: 1.7;
	}

	.partners-block .imgSmall {
		max-width: 100px !important;
	}

	.desc-text {
		font-size: 14px;
	}

}

@media screen and (max-width: 375px) {
	.MuiPaper-elevation8 p {
		width: 320px;
	}

	.MuiPaper-elevation8 .font-sm {
		font-size: 14px;
	}

	.address .address-lock {
		display: none;
	}
}